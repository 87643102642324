import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_USUARIOS

export default class EmpleadosService {
  post (params) {
    return http.post(`${baseUrl}/empleados/crear-empleado`, params, {
      headers: {
        loading: true
      }
    })
  }

  paginate (params) {
    return http.get(`${baseUrl}/empleados/listar-empleados`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  find (id) {
    return http.get(`${baseUrl}/empleados/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  put (params) {
    return http.put(`${baseUrl}/empleados/actualizar`, params, {
      headers: {
        loading: true
      }
    })
  }

  getAll () {
    return http.get(`${baseUrl}/empleados/listar-empleados-todos`, {
      headers: {
        loading: true
      }
    })
  }

  getSocialSecurity () {
    return http.get(`${baseUrl}/empleados/listar-seguridad-social`, {
      headers: {
        loading: true
      }
    })
  }

  putSocialSecurity (params) {
    return http.put(`${baseUrl}/empleados/actualizar-seguridad-social`, params, {
      headers: {
        loading: true
      }
    })
  }
}
