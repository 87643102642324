import { createStore } from 'vuex'
import EmpleadosService from '../services/empleados.service'
const _EmpleadosService = new EmpleadosService()

const empleadosStore = {
  state: {
    empleados: [],
    dataEmpleado: {},
    dataPersona: {},
    dataContratos: {},
    cargo: {},
    contrato: {},
    localizacion: {},
    sede: '',
    area: '',
    sub_area: {},
    empleado: {
      Afp: '',
      Area: '',
      Cargo: '',
      Ccf: '',
      Eps: '',
      EstadoCivil: '',
      FechaFin: '',
      FechaInicio: '',
      FechaNacimiento: '',
      Genero: '',
      JefeInmediato: '',
      License_plate: '',
      NumeroHijos: '',
      ReferenciaId: '',
      Salario: '',
      Sede: '',
      SubArea: '',
      TieneHijos: '',
      TipoContratacion: '',
      createdAt: '',
      createdByBase: '',
      createdByType: '',
      deletedAt: '',
      id: '',
      state: '',
      updatedAt: '',
      updatedByBase: '',
      updatedByType: '',
      vehicle: ''
    },
    persona: {
      createdAt: '',
      createdByBase: '',
      createdByType: '',
      deletedAt: '',
      direccion: '',
      email: '',
      firt_name: '',
      full_name: '',
      id: '',
      imagen: '',
      last_name: '',
      localizacion: '',
      number_document: '',
      telefono: '',
      type_document_id: '',
      updatedAt: '',
      updatedByBase: '',
      updatedByType: ''
    },
    documentos: [{
      createdAt: '',
      deletedAt: '',
      employeeId: '',
      id: '',
      level: '',
      path: '',
      title: '',
      updatedAt: ''
    }],
    filtro_tabla_empleados_nombre: '',
    editar: false
  },
  getters: {
    _empleados: (state) => state.empleados,
    _empleado: (state) => state.empleado,
    _persona: (state) => state.persona,
    _documentos: (state) => state.documentos,
    _filtro_tabla_empleados_nombre: (state) => state.filtro_tabla_empleados_nombre,
    _editar: (state) => state.editar,
    _dataEmpleado: (state) => state.dataEmpleado,
    _dataPersona: (state) => state.dataPersona,
    _dataContratos: (state) => state.dataContratos,
    _cargo: (state) => state.cargo,
    _localizacion: (state) => state.localizacion,
    _contrato: (state) => state.contrato,
    _area: (state) => state.area,
    _sub_area: (state) => state.sub_area,
    _sede: (state) => state.sede
  },
  mutations: {
    setEmpleados (state, payload) {
      state.empleados = payload
    },
    setEmpleado (state, payload) {
      state.empleado = payload
    },
    setPersona (state, payload) {
      state.persona = payload
    },
    setDocumentos (state, payload) {
      state.documentos = payload
    },
    setFiltroPorNombreTabla (state, payload) {
      state.filtro_tabla_empleados_nombre = payload
    },
    setEditar (state, payload) {
      state.editar = payload
    },
    setDataEmpleado (state, payload) {
      state.dataEmpleado = payload
    },
    setDataPersona (state, payload) {
      state.dataPersona = payload
    },
    setDataContratos (state, payload) {
      state.dataContratos = payload
    },
    setCargo (state, payload) {
      state.cargo = payload
    },
    setLocalizacion (state, payload) {
      state.localizacion = payload
    },
    setContrato (state, payload) {
      state.contrato = payload
    },
    setArea (state, payload) {
      state.area = payload
    },
    setSubArea (state, payload) {
      state.sub_area = payload
    },
    setSede (state, payload) {
      state.sede = payload
    }
  },
  actions: {
    getEmpleados ({ commit }, payload) {
      _EmpleadosService.paginate(payload).then(({ data }) => {
        commit('setEmpleados', data)
      })
    },
    post ({ commit }, payload) {
      return _EmpleadosService.post(payload).then((response) => {
        return response
      })
    },
    filtroPorNombreTabla ({ commit }, payload) {
      commit('setFiltroPorNombreTabla', payload)
    },
    find ({ commit }, id) {
      return _EmpleadosService.find(id).then(({ data }) => {
        commit('setEmpleado', data)
      })
    },
    setComponents  ({ commit }, payload) {
      commit('setEmpleado', payload)
      commit('setDocumentos', payload.documentos)
    }
  }
}
const store = createStore(empleadosStore)
export default {
  namespaced: true,
  ...store
}
