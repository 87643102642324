<template>
    <section>
      <div class="w-full">
        <div class="w-full bg-gray-400 p-4">
          <div class="flex justify-between">
            <div>
              <div class="buttonBack">
                <Button
                  @click="$router.back()"
                  style="border: transparent"
                  class="mr-4 mb-2 buttonBack font-medium bg-gray-600"
                  label="Volver"
                  icon="pi pi-angle-left" />
              </div>
            </div>
            <div class="flex items-center text-gray-600 font-bold">
              <i class="pi pi-info-circle mr-2 font-bold"></i>
              <p class="text-xl">
                Información
              </p>
            </div>
            <div v-if="$can('pharmasan.recursos-humanos.empleados.access')">
              <Button icon="pi pi-pencil" @click="$router.push({name: 'pharmasan.recursos-humanos.empleados.editar', params: {id: $route.params.id}})" class="mr-2 px-4" label="Editar usuario" />
            </div>
          </div>
        </div>
        <div class="w-full bg-white px-10 py-4">
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div>
                  <Header :empleado="empleado" :persona="persona" />
                  <br>
                  <PersonalData :empleado="empleado" :persona="persona" />
                </div>
                <div>
                  <AcademyData :documentos="documentos" />
                  <br>
                  <EmployeeData :empleado="empleado" :persona="persona" />
                </div>
            </div>
        </div>
      </div>
    </section>
  </template>
  <script>
  import EmpleadosService from '../../../../services/empleados.service'
  import ContratosService from '../../../../services/contratos.service'
  import SedesService from '../../../../services/sedes.service'
  import AreasService from '../../../../services/areas.service'
  import CargosService from '../../../../services/cargos.service'
  import LocalizacionService from '../../../../services/localizacion.service'
  import empleadosStore from '../../../../stores/empleados.store'
  import { onMounted, defineAsyncComponent, ref } from 'vue'
  import { useRoute } from 'vue-router'

  export default {
    name: 'VerEmpleados',
    components: {
        Header: defineAsyncComponent(() => import('./components/header.vue')),
        PersonalData: defineAsyncComponent(() => import('./components/personalData.vue')),
        AcademyData: defineAsyncComponent(() => import('./components/academyData.vue')),
        EmployeeData: defineAsyncComponent(() => import('./components/employeeData.vue'))
    },
    setup () {
      // Services
      const _EmpleadosService = ref(new EmpleadosService())
      const _CargosService = ref(new CargosService())
      const _LocalizacionService = ref(new LocalizacionService())
      const _ContratosService = ref(new ContratosService())
      const _AreasService = ref(new AreasService())
      const _SedesService = ref(new SedesService())
      const route = useRoute()
      // References
      const empleado = ref({
        Afp: '',
        Area: '',
        Cargo: '',
        Ccf: '',
        Eps: '',
        EstadoCivil: '',
        FechaFin: '',
        FechaInicio: '',
        FechaNacimiento: '',
        Genero: '',
        JefeInmediato: '',
        License_plate: '',
        NumeroHijos: '',
        ReferenciaId: '',
        Salario: '',
        Sede: '',
        SubArea: '',
        TieneHijos: '',
        TipoContratacion: '',
        createdAt: '',
        createdByBase: '',
        createdByType: '',
        deletedAt: '',
        id: '',
        state: '',
        updatedAt: '',
        updatedByBase: '',
        updatedByType: '',
        vehicle: ''
      })
      const persona = ref({
        createdAt: '',
        createdByBase: '',
        createdByType: '',
        deletedAt: '',
        direccion: '',
        email: '',
        firt_name: '',
        full_name: '',
        id: '',
        imagen: '',
        last_name: '',
        localizacion: '',
        number_document: '',
        telefono: '',
        type_document_id: '',
        updatedAt: '',
        updatedByBase: '',
        updatedByType: ''
      })
      const documentos = ref([{
        createdAt: '',
        deletedAt: '',
        employeeId: '',
        id: '',
        level: '',
        path: '',
        title: '',
        updatedAt: ''
      }])
      // Methods
      const getEmpleado = () => {
        _EmpleadosService.value.find(parseInt(route.params.id)).then(({ data }) => {
          empleadosStore.commit('setDataPersona', data.persona)
          empleadosStore.commit('setDataContratos', data.contratos)
          empleadosStore.dispatch('setComponents', data)
          empleado.value = data
          persona.value = data.persona
          documentos.value = data.documentos
          getCargos(data.Cargo)
          getLocalizacion(data.persona.localizacion)
          getContratos(data.contratos.tipo_contrato_id)
          getArea(data.contratos.estacion_trabajo.area_id, data.contratos.estacion_trabajo.subarea_id)
          getSedes(data.contratos.estacion_trabajo.branch_office_id)
          listarSeguridadSocial(data)
        })
      }
      const getCargos = (id) => {
        _CargosService.value.paginate().then(({ data }) => {
          empleadosStore.commit('setCargo', data.filter(a => a.id === parseInt(id))[0])
        })
      }
      const getLocalizacion = (id) => {
        _LocalizacionService.value.paginate().then(({ data }) => {
          empleadosStore.commit('setLocalizacion', data.filter(a => a.id === parseInt(id))[0])
        })
      }
      const getContratos = (id) => {
        _ContratosService.value.paginate().then(({ data }) => {
          empleadosStore.commit('setContrato', data.filter(a => a.id === parseInt(id))[0])
        })
      }
      const getArea = (id, idSub) => {
        _AreasService.value.paginate().then(({ data }) => {
          const dataArea = data.filter(a => a.id === parseInt(id))[0].name
          empleadosStore.commit('setArea', dataArea)
          empleadosStore.commit('setSubArea', data.filter(a => a.id === parseInt(idSub))[0])
        })
      }
      const getSedes = (id) => {
        _SedesService.value.paginate().then(({ data }) => {
          empleadosStore.commit('setSede', data.filter(a => a.id === parseInt(id))[0].name)
        })
      }
      const listarSeguridadSocial = (info) => {
        _EmpleadosService.value.getSocialSecurity().then(({ data }) => {
          if (data.length) {
            for (const i of data) {
              if (i.id === parseInt(info.Eps) && i.type === 1) {
                info.Eps = i.name
              }
              if (i.id === parseInt(info.Afp) && i.type === 2) {
                info.Afp = i.name
              }
              if (i.id === parseInt(info.Ccf) && i.type === 3) {
                info.Ccf = i.name
              }
            }
          }
          empleadosStore.commit('setDataEmpleado', info)
        })
      }

      // Omounted
      onMounted(() => {
        getEmpleado()
      })

      return {
        empleado,
        persona,
        documentos
      }
    }
  }
  </script>

  <style lang="scss" scoped>
  ::v-deep(.p-dropdown-trigger) {
    display: none;
  }
  ::v-deep(.p-button.p-component.p-button-icon-only.p-datepicker-trigger) {
    background-color: rgba(113, 128, 150, var(--tw-text-opacity)) !important;
    border-color: rgba(113, 128, 150, var(--tw-text-opacity)) !important;
}
  </style>
